import React from "react";
export const PaginationRightArrow = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0746 19.4419C10.8085 19.1979 10.8085 18.8021 11.0746 18.5581L15.5625 14.4419C15.8286 14.1979 15.8286 13.8021 15.5625 13.5581L11.0746 9.44194C10.8085 9.19786 10.8085 8.80214 11.0746 8.55806C11.3407 8.31398 11.7722 8.31398 12.0383 8.55806L16.5262 12.6742C17.3246 13.4064 17.3246 14.5936 16.5262 15.3258L12.0383 19.4419C11.7722 19.686 11.3407 19.686 11.0746 19.4419Z"
        fill="#1C1C1C"
      />
    </svg>
  );
};
