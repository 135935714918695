import React from 'react';
export const CheckReady = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16.0022L13.3337 21.1451L24 10.8594"
        stroke={color || 'url(#paint0_linear_9625_198742)'}
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9625_198742"
          x1="9.86436"
          y1="17.332"
          x2="22.2468"
          y2="17.6391"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
