import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert as BootstrapAlert } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Skeleton from 'react-loading-skeleton';
import { filter } from 'smart-array-filter';
import { setAllFilter } from '../../../Store/reducers/orders';
import { Button, FormSearch, HemaValue, Pagination, FilterColumn } from '../../../utils';

import binocularIcon from '../../../assets/images/binocular.svg';
import { getDetailRecordIdAction } from '../../../Action/order';
import { sortedData } from '../../../helpers/sort';

// Utils
import { getFullName } from '../Utils';
import { TableSortIcon } from '../../../HemeIconLibrary';

// assets

const All = ({ setShowDetial, data }) => {
  const dispatch = useDispatch();
  const { settings, builder, orders } = useSelector((state) => state);
  const { kitTemplateFilter } = builder;
  const [searchQuery, setsearchQuery] = useState('');
  const [dataList, setDataList] = useState(null);
  const [filterbuilder, setfilterbuilder] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [sortIcon, setSortIcon] = useState();

  useEffect(() => {
    if (data) {
      setDataList(data || []);
    }
  }, [data]);

  // Search Green light ...
  useEffect(() => {
    const searchdataList = dataList?.filter(
      (item) =>
        item?.shipment?.sponsor?.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.shipment?.order?.orderCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.shipment?.studyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.shipment?.order?.siteCode.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item?.status?.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    setSearchResult(searchdataList);
  }, [dataList, searchQuery]);

  //search for kit builder
  useEffect(() => {
    (async () => {
      if (searchQuery) {
        const searchResult = filter(builder?.allTemplates, {
          keywords: searchQuery, // search for any field that contains the "Do" string

          caseSensitive: false,
        });
        setfilterbuilder(searchResult);
      } else {
        setfilterbuilder(builder?.allTemplates);
      }
    })();
  }, [searchQuery, builder?.allTemplates]);

  // sorting for green light template
  const caseInsensitiveSort = (rowA, rowB) => {
    const a = rowA.name?.toLowerCase();
    const b = rowB.name?.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };

  const sortQuantitySum = (rowA, rowB) => {
    const itemOneQtySum = rowA.items?.reduce((accumulator, currentValue) => accumulator + currentValue.itemPerKit, 0);
    const itemTwoQtySum = rowB.items?.reduce((accumulator, currentValue) => accumulator + currentValue.itemPerKit, 0);

    if (itemOneQtySum > itemTwoQtySum) {
      return 1;
    }

    if (itemTwoQtySum > itemOneQtySum) {
      return -1;
    }

    return 0;
  };

  //search for location
  // useEffect(() => {
  //   (async () => {
  //     if (searchQuery) {
  //       const filteredDataResult = filter(systemUsers, {
  //         keywords: searchQuery, // search for any field that contains the "Do" string

  //         caseSensitive: false,
  //       });
  //       setDataList(filteredDataResult);
  //     } else {
  //       setDataList(orders.allOrders);
  //     }
  //   })();
  // }, [searchQuery, orders.allOrders]);

  const SkelatonCoponent = () => {
    return (
      <>
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
        <Skeleton count={4} />
        <br />
      </>
    );
  };

  // useEffect(() => {
  //   if (Object.keys(systemUsersFilter)?.length) {
  //     const filterResult = systemUsers?.filter((port) => {
  //       if (
  //         (systemUsersFilter.email?.length
  //           ? systemUsersFilter.email.includes(port.email)
  //           : true) &&
  //         (systemUsersFilter.isActive?.length
  //           ? systemUsersFilter.isActive?.includes(port.isActive)
  //           : true) &&
  //         (systemUsersFilter.firstName?.length
  //           ? systemUsersFilter.firstName?.includes(getFullName(port))
  //           : true) &&
  //         (systemUsersFilter.role?.length
  //           ? systemUsersFilter.role?.includes(port.role.name)
  //           : true)
  //       ) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     });
  //     setDataList(filterResult);
  //   } else {
  //    // setDataList(systemUsers);
  //   }
  // }, [JSON.stringify(systemUsersFilter)]);
  return (
    <div className="bg-white rounded-[5px]  mt-[27px] mb-[13px] inventory-tabs">
      <>
        <div className="mb-[10px] px-[16px]">
          <FormSearch w="w-[400px]" searchQuery={searchQuery} setsearchQuery={setsearchQuery} />
        </div>
        {!searchResult ? (
          <SkelatonCoponent />
        ) : searchResult?.length > 0 || Object.keys(searchResult)?.length ? (
          <DataTable
            className="hdxa-rdt-filter w-full border-t-[1px] border-solid border-t-[#DEE2E6] managment-table-st"
            data={searchResult}
            customStyles={{
              rows: {
                style: {
                  paddingRight: '20px',
                },
              },
            }}
            columns={[
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Order Confirmation Number'} className="font-normal text-xs leading-[18px] " color="text-[#000000]" />
                      {sortIcon === 'asc' ? (
                        <span className="short-icon rotate-180">
                          <TableSortIcon />
                        </span>
                      ) : (
                        <span className="short-icon ">
                          <TableSortIcon />
                        </span>
                      )}
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <FilterColumn
                        columnName="firstName"
                        setRedux={setAllFilter}
                        reduxValues={searchResult || []}
                        options={Array.from(new Set(searchResult?.map((filter) => filter.shipment?.order?.orderCode)))}
                      />
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => (
                  <>
                    <HemaValue className=" text-xs leading-[18px]" text={row?.shipment?.order?.orderCode} />
                  </>
                ),
                sortFunction: caseInsensitiveSort,
                sortId: 'shipment.order.orderCode',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Sponsor'} className="font-normal text-xs leading-[18px] " color="text-[#000000]" />
                      {sortIcon === 'asc' ? (
                        <span className="short-icon rotate-180">
                          <TableSortIcon />
                        </span>
                      ) : (
                        <span className="short-icon ">
                          <TableSortIcon />
                        </span>
                      )}
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <FilterColumn
                        columnName="name"
                        setRedux={setAllFilter}
                        reduxValues={searchResult || []}
                        options={Array.from(new Set(searchResult?.map((filter) => filter.shipment?.sponsor?.name)))}
                      />
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                sortFunction: sortQuantitySum,
                selector: (row, index) => (
                  <>
                    <HemaValue className=" text-xs leading-[18px]" text={row?.shipment?.sponsor?.name} />
                  </>
                ),
                sortId: 'shipment.sponsor.name',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Study Name'} className="font-normal text-xs leading-[18px] " color="text-[#000000]" />
                      {sortIcon === 'asc' ? (
                        <span className="short-icon rotate-180">
                          <TableSortIcon />
                        </span>
                      ) : (
                        <span className="short-icon ">
                          <TableSortIcon />
                        </span>
                      )}
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <FilterColumn
                        columnName="role"
                        setRedux={setAllFilter}
                        reduxValues={searchResult || []}
                        options={Array.from(new Set(searchResult?.map((filter) => filter.shipment?.studyName)))}
                      />
                    </div>
                  </div>
                ),
                sortable: true,
                filterable: true,
                selector: (row, index) => <HemaValue className=" text-xs leading-[18px]" text={row?.shipment?.studyName} />,
                sortId: 'shipment.studyName',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Site Code'} className="font-normal text-xs leading-[18px] " color="text-[#000000]" />
                      {sortIcon === 'asc' ? (
                        <span className="short-icon rotate-180">
                          <TableSortIcon />
                        </span>
                      ) : (
                        <span className="short-icon ">
                          <TableSortIcon />
                        </span>
                      )}
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <FilterColumn
                        columnName="isActive"
                        type="boolean"
                        boolTrueText="Active"
                        boolFalseText="In-Active"
                        setRedux={setAllFilter}
                        reduxValues={searchResult || []}
                        options={Array.from(new Set(searchResult?.map((filter) => filter.shipment?.order?.siteCode)))}
                      />
                    </div>
                  </div>
                ),
                sortable: true,
                selector: (row, index) => <HemaValue className=" text-xs leading-[18px]" text={row.shipment?.order?.siteCode} />,
                sortId: 'shipment.order.siteCode',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="w-fit flex items-center gap-1 py-[8px] px-[10px]">
                      <HemaValue text={'Status'} className="font-normal text-xs leading-[18px] " color="text-[#000000]" />
                      {sortIcon === 'asc' ? (
                        <span className="short-icon rotate-180">
                          <TableSortIcon />
                        </span>
                      ) : (
                        <span className="short-icon ">
                          <TableSortIcon />
                        </span>
                      )}
                    </div>
                    <div className="w-full border-t-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <FilterColumn
                        columnName="isActive"
                        type="boolean"
                        boolTrueText="Active"
                        boolFalseText="In-Active"
                        setRedux={setAllFilter}
                        reduxValues={searchResult || []}
                        options={Array.from(new Set(searchResult?.map((filter) => filter.status?.name)))}
                      />
                    </div>
                  </div>
                ),
                sortable: true,
                selector: (row, index) => (
                  <>
                    <div
                      className={`flex items-center w-fit gap-1 cursor-pointer px-2 py-1 rounded-full ${
                        (row?.status?.name === 'Pending' && 'bg-[#FDEBE1]') ||
                        (row?.status?.name === 'Canceled' && 'bg-[#F6E6F2]') ||
                        (row?.status?.name === 'Approved' && 'bg-[#D3EADC]') ||
                        (row?.status?.name === 'Rejected' && 'bg-[#f5e2e1]')
                      }`}
                    >
                      <div
                        className={`w-2 h-2 rounded-full ${
                          (row?.status?.name === 'Pending' && 'bg-[#F97316]') ||
                          (row?.status?.name === 'Canceled' && 'bg-[#C92286]') ||
                          (row?.status?.name === 'Approved' && 'bg-[#0AB745]') ||
                          (row?.status?.name === 'Rejected' && 'bg-[#f50b0b]')
                        }`}
                      />

                      <HemaValue
                        className="text-[12px] leading-[18px]"
                        color={
                          (row?.status?.name === 'Pending' && 'text-[#F97316]') ||
                          (row?.status?.name === 'Canceled' && 'text-[#C92286]') ||
                          (row?.status?.name === 'Approved' && 'text-[#0AB745]') ||
                          (row?.status?.name === 'Rejected' && 'text-[#f50b0b]')
                        }
                        text={row?.status?.name}
                      />
                    </div>
                  </>
                ),
                sortId: 'status.name',
              },
              {
                name: (
                  <div className="w-full">
                    <div className="py-[10px] w-full border-b-[1px] border-solid border-[#DEE2E6] px-[10px]">
                      <HemaValue text="Actions" color="text-[#000000]" className="text-xs font-normal pr-7" />
                    </div>
                  </div>
                ),
                selector: (row, index) => (
                  <div className="flex">
                    <div className="flex w-[100px] justify-end meta">
                      <Button
                        Icon={<img src={binocularIcon} alt="" />}
                        padding={false}
                        color="text-[#dc2626]"
                        bg="bg-bgActionDots"
                        cta={async () => {
                          const result = await getDetailRecordIdAction(row.id);
                          if (result.status === 200) {
                            setShowDetial(true);
                          }
                        }}
                      />
                    </div>
                  </div>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
              },
            ]}
            pagination={true}
            onSort={(row, direction, sorted) => {
              setSortIcon(direction);
              setDataList(sortedData(row.sortId, direction, sorted)?.filter((data) => Object.keys(data)?.length));
            }}
            paginationComponent={(e) => {
              return <Pagination e={e} />;
            }}
          />
        ) : (
          <BootstrapAlert variant="warning" className="mt-3 text-center">
            No records available..
          </BootstrapAlert>
        )}
      </>
    </div>
  );
};

export default All;
