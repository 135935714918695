import React from 'react';

export const Assemblies = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9199 9.51043L15.4943 8.81358L15.0671 9.50945L13.1066 12.7027L12.6391 13.4643H13.5328H17.4435H18.3348L17.8702 12.7037L15.9199 9.51043ZM8.41949 19.0327H7.91949V19.5327V23.5748V24.0748H8.41949H12.4616H12.9616V23.5748V19.5327V19.0327H12.4616H8.41949ZM10.8268 14.4854L15.4932 6.84949L20.1595 14.4854H10.8268ZM17.0037 21.5538C17.0037 19.3137 18.811 17.5064 21.0511 17.5064C23.2911 17.5064 25.0984 19.3137 25.0984 21.5538C25.0984 23.7939 23.2911 25.6012 21.0511 25.6012C18.811 25.6012 17.0037 23.7939 17.0037 21.5538ZM18.0248 21.5538C18.0248 23.2245 19.3804 24.5801 21.0511 24.5801C22.7217 24.5801 24.0774 23.2245 24.0774 21.5538C24.0774 19.8831 22.7217 18.5275 21.0511 18.5275C19.3804 18.5275 18.0248 19.8831 18.0248 21.5538ZM13.9826 18.0117V25.0959H6.89844V18.0117H13.9826Z"
        fill={color || 'url(#paint0_linear_9625_196691)'}
        stroke={color || 'url(#paint1_linear_9625_196691)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_9625_196691"
          x1="8.63567"
          y1="18.6088"
          x2="23.5004"
          y2="18.834"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9625_196691"
          x1="8.63567"
          y1="18.6088"
          x2="23.5004"
          y2="18.834"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#775FD5" />
          <stop offset="1" stop-color="#F03A85" />
        </linearGradient>
      </defs>
    </svg>
  );
};
