import React from "react";
export const Confirm = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7046 3.18496C14.6116 3.09123 14.501 3.01684 14.3792 2.96607C14.2573 2.9153 14.1266 2.88916 13.9946 2.88916C13.8626 2.88916 13.7319 2.9153 13.61 2.96607C13.4881 3.01684 13.3775 3.09123 13.2846 3.18496L5.83458 10.645L2.70458 7.50496C2.60806 7.41172 2.49412 7.33841 2.36926 7.2892C2.24441 7.24 2.11108 7.21587 1.9769 7.21819C1.84272 7.22051 1.71031 7.24924 1.58723 7.30273C1.46415 7.35623 1.35282 7.43344 1.25958 7.52996C1.16634 7.62648 1.09303 7.74042 1.04382 7.86528C0.994618 7.99013 0.970488 8.12346 0.972809 8.25764C0.975131 8.39182 1.00386 8.52423 1.05735 8.6473C1.11085 8.77038 1.18806 8.88172 1.28458 8.97496L5.12458 12.815C5.21754 12.9087 5.32814 12.9831 5.45 13.0338C5.57186 13.0846 5.70257 13.1108 5.83458 13.1108C5.96659 13.1108 6.0973 13.0846 6.21916 13.0338C6.34101 12.9831 6.45162 12.9087 6.54458 12.815L14.7046 4.65496C14.8061 4.56132 14.8871 4.44766 14.9425 4.32117C14.9979 4.19467 15.0265 4.05806 15.0265 3.91996C15.0265 3.78186 14.9979 3.64525 14.9425 3.51875C14.8871 3.39225 14.8061 3.2786 14.7046 3.18496Z"
        fill="white"
      />
    </svg>
  );
};
