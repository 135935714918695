import React from 'react';
import { FormSelect } from '../../utils';
import {
  PaginationRightArrow,
  PaginationLeftArrow,
} from '../.././HemeIconLibrary';

const Pagination = (props) => {
  const { e } = props;
  return (
    <div className="flex items-center justify-between pagination-wrapper px-[10px] pt-[10px] border-t-[1px] border-solid border-[#DEE2E6] ">
      {e?.rowCount && (
        <div className="flex items-center gap-[8px]">
          <p className="font-medium text-sm text-[#454545] m-0">Showing</p>
          <FormSelect
            options={[
              { name: 10, id: 10 },
              { name: 20, id: 20 },
              { name: 30, id: 30 },
              { name: 'All', id: 'All' },
            ]}
            placeholder={
              e?.rowsPerPage === e?.rowCount ? 'All' : e?.rowsPerPage
            }
            setFieldValue={(f, total) => {
              if (total === 'All') {
                e.onChangeRowsPerPage(e?.rowCount, 1);
              } else {
                e.onChangeRowsPerPage(total, 1);
              }
            }}
            className="w-[100px]"
          />
          <p className="font-semibold text-sm  text-[#454545] m-0">
            of {e?.rowCount}
          </p>
        </div>
      )}

      <div className="flex items-center gap-[8px]">
        <div
          className={
            e.rowsPerPage >= e.rowCount ? 'cursor-default' : 'cursor-pointer'
          }
          onClick={() => {
            console.log(e);
            if (e.currentPage !== 1) {
              e.onChangePage(e.currentPage - 1, e?.rowsPerPage);
            }
          }}
        >
          {e.rowsPerPage >= e.rowCount ? (
            <PaginationLeftArrow color="#cccccc" />
          ) : (
            <PaginationLeftArrow />
          )}
        </div>
        <div className="flex items-center gap-[8px]">
          {[...Array(Math.ceil(e.rowCount / e?.rowsPerPage)).keys()].map(
            (numb) => {
              return (
                <div
                  className={`w-7 h-7 flex items-center justify-center flex-shrink-0 rounded-[5px] font-normal text-[12px] leading-[18px]  cursor-pointer hover:bg-primary1 hover:text-[white]  ${
                    e.currentPage === numb + 1 && 'bg-primary1 text-[white]'
                  }`}
                  onClick={() => {
                    e.onChangePage(numb + 1, e?.rowsPerPage);
                  }}
                >
                  {numb + 1}
                </div>
              );
            }
          )}
        </div>
        <div
          className={
            e.rowsPerPage >= e.rowCount ? 'cursor-default' : 'cursor-pointer '
          }
          onClick={() => {
            // if(e.currentPage!==1) {
            e.onChangePage(e.currentPage + 1, e?.rowsPerPage);
            // }
            // e.onChangePage(
            //   Math.ceil(e.rowCount / e?.rowsPerPage),
            //   e?.rowsPerPage
            // );
          }}
        >
          {e.rowsPerPage >= e.rowCount ? (
            <PaginationRightArrow color="#cccccc" />
          ) : (
            <PaginationRightArrow />
          )}
        </div>
      </div>
    </div>
  );
};
export default Pagination;
