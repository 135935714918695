import React, { useEffect } from 'react';

import AppRouter from './appRouter';

const App = () => {


  return <AppRouter />;
};

export default App;
